/* eslint-disable react/jsx-indent */
import React from 'react'

const KuResult = ({ku}) => {

  if (ku === undefined) {
    return (<></>)
  }

  const { title, county, id, price } = ku

  return (
    <div className="row">
      <div className="col-2 mt-2">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="coins" className="svg-inline--fa fa-coins fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#d32f2f" d="M0 405.3V448c0 35.3 86 64 192 64s192-28.7 192-64v-42.7C342.7 434.4 267.2 448 192 448S41.3 434.4 0 405.3zM320 128c106 0 192-28.7 192-64S426 0 320 0 128 28.7 128 64s86 64 192 64zM0 300.4V352c0 35.3 86 64 192 64s192-28.7 192-64v-51.6c-41.3 34-116.9 51.6-192 51.6S41.3 334.4 0 300.4zm416 11c57.3-11.1 96-31.7 96-55.4v-42.7c-23.2 16.4-57.3 27.6-96 34.5v63.6zM192 160C86 160 0 195.8 0 240s86 80 192 80 192-35.8 192-80-86-80-192-80zm219.3 56.3c60-10.8 100.7-32 100.7-56.3v-42.7c-35.5 25.1-96.5 38.6-160.7 41.8 29.5 14.3 51.2 33.5 60 57.2z"></path></svg>
      </div>
      <div className="col-10">
        <strong className="target-info">
          Cena:&nbsp;
        </strong>
        <strong className="target-price">
          {price}/ha
        </strong>
        <div className="location">k.ú. {title}, {county}, {id} </div>
      </div>
      <div className="col-12 mt-1">
        <p>Trhová cena poľnohospodárskej pôdy se bude najpravdepodobnejšie približovať tejto cene cca. +/- 20%.</p>
      </div>
    </div>
  )
}

export default KuResult
