/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import { Field, reduxForm } from 'redux-form'

const validate = (values) => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Povinné'
  }

  if (!values.email) {
    errors.email = 'Povinné'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Neplatný e-mail'
  }

  if (!values.message) {
    errors.message = 'Povinné'
  }

  return errors
}

const textField = ({
  input,
  type,
  className,
  placeholder,
  meta: { touched, error, warning }
}) => (
  <>
    <input {...input} placeholder={placeholder} type={type} className={className} />
    <div>
      {touched
        && ((error && <p className="text-danger mt-2 mb-2">{error}</p>)
        || (warning && <p className="text-danger mt-2 mb-2">{warning}</p>))}
    </div>
  </>
)

const textArea = ({ input, className, placeholder, meta: { touched, error, warning }}) => (
  <>
    <textarea {...input} placeholder={placeholder} className={className} />
    <div>
      {touched
        && ((error && <p className="text-danger mt-2 mb-2">{error}</p>)
        || (warning && <p className="text-danger mt-2 mb-2">{warning}</p>))}
    </div>
  </>
)

const ContactForm = (props) => {
  // eslint-disable-next-line react/prop-types
  const { handleSubmit, pristine, submitting, error, submitSucceeded } = props

  return (
    <>
      { submitSucceeded && <div className="alert alert-success">Správa úspešne odoslaná.</div> }
      { error && <div className="alert alert-danger">{error}</div> }

      <form onSubmit={handleSubmit}>
        <Field
          name="name"
          component={textField}
          type="text"
          placeholder="Jméno *"
          className="form-control form-control-lg mt-2"
        />
        <Field
          name="email"
          component={textField}
          type="text"
          placeholder="E-mail *"
          className="form-control form-control-lg mt-2"
        />
        <Field
          name="phone"
          component={textField}
          type="text"
          placeholder="Telefónne číslo (nepovinný údaj)"
          className="form-control form-control-lg mt-2"
        />
        <Field name="message" component={textArea} className="form-control form-control-lg mt-2" placeholder="Správa *" />
        <div className="text-right">
          <button type="submit" className="btn btn-success" disabled={submitting} style={{ marginTop: '15px' }}>
            ODOSLAŤ
          </button>
        </div>
      </form>
    </>
  )
}

export default reduxForm({
  form: 'contact', // a unique identifier for this form
  validate
})(ContactForm)
