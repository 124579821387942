import rp from 'request-promise'

import { SubmissionError, reset } from 'redux-form'
import { BACKEND_URL } from './config'

let dispatch = null

export function setDispatch(disp) {
  dispatch = disp
}

const errorMessage = 'Chyba při odesílání formuláře.'

export function submitForm(values) {
  const options = {
    method: 'POST',
    uri: `${BACKEND_URL}/api/submit`,
    body: values,
    json: true
  }

  return rp(options)
    .then((parsedBody) => {
      console.log('BODY', parsedBody)
      if (parsedBody && parsedBody.success !== true) {
        throw new SubmissionError({
          _error: errorMessage
        })
      } else {
        dispatch(reset('contact'))
      }
    })
    .catch((err) => {
      console.log(err)
      throw new SubmissionError({
        _error: errorMessage
      })
    })
}
