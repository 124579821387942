// DEV
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import 'regenerator-runtime/runtime' // potřeba pro sagu a redux-form a jiné

// APP
import { store } from './rootStore'
import App from './App'

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('w-search'))

// Potřeba pro Hot Module Replacement
if (typeof (module.hot) !== 'undefined') {
  module.hot.accept() // eslint-disable-line no-undef
}
