// DEP
import { createStore, applyMiddleware, compose } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = []

const combineReducers = (reducers) => {
  return (state = {}, action) => {
    return Object.keys(reducers).reduce(
      (nextState, key) => {
        // eslint-disable-next-line no-param-reassign
        nextState[key] = reducers[key](
          state[key],
          action,
          state
        )
        return nextState
      },
      {}
    )
  }
}

const combinedReducer = combineReducers(
  {
    form: reduxFormReducer,
  }
)


export const store = createStore(
  combinedReducer,
  composeEnhancers(applyMiddleware(
    ...middlewares
  )),
)

export default store
