/* eslint-disable no-undef */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { createPortal } from 'react-dom'
import { scroller } from 'react-scroll'
import './app.css'
import Autocomplete from './Autocomplete'
import KuResult from './KuResult'
import ContactForm from './ContactForm'
import { submitForm, setDispatch } from './submitForm'

const resultRoot = document.getElementById('w-search-result')
const contactFormRoot = document.getElementById('react-form')

const Portal = ({ children, domNode }) => createPortal(
  children,
  domNode
)

let setPageGlobal = null

$('#button-sell').on('click', () => {
  setPageGlobal('page-sell')
})

const setPageVisibilty = (page) => {
  const selector = '*[class^="page-"]'

  $(selector).each((index, el) => {
    if (!$(el).hasClass(page)) {
      $(el).fadeOut()
    } 
  })  
  setTimeout(() => {
    $(selector).each((index, el) => {
      if ($(el).hasClass(page)) {
        $(`.${page}`).fadeIn(
          setTimeout(() => {
            scroller.scrollTo(page, {
              smooth: true,
              duration: 600
            })
          }, 200)
        )
      } 
    })
  },300)
  

}

const App = () => {
  const dispatch = useDispatch()
  setDispatch(dispatch)
  const [cadastralArea, setCadastralArea] = useState(undefined)
  const [page, setPage] = useState('page-hp')
  setPageGlobal = setPage
  // console.log('App', cadastralArea)
  if(page !== 'page-hp')
    setPageVisibilty(page)

  return (
    <div className="row align-items-center">
      <div className="col-12 col-sm-6 offset-sm-3">
        <Autocomplete setCadastralArea={setCadastralArea} setPage={setPage} />
      </div>
      <Portal domNode={resultRoot}>
        <KuResult ku={cadastralArea} />
      </Portal>

      {page === 'page-sell'
        && (
          <>
            <Portal domNode={contactFormRoot}>
              <ContactForm onSubmit={submitForm} />
            </Portal>
          </>
        )}

    </div>
  )
}

export default App
