/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react'
import Autosuggest from 'react-autosuggest'
import rp from 'request-promise'
import Diacritics from 'diacritic'
// import { scroller } from 'react-scroll'
import { BACKEND_URL } from './config'

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.title

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a href={`#${suggestion.slug}`}>
    {suggestion.title}
  </a>
)

const renderInputComponent = (inputProps, handleOnView) => (
  <div className="input-group input-group-lg">
    <input {...inputProps} />
    <div className="input-group-append" id="button-addon4">
      <button className="btn btn-success" type="button" onClick={() => handleOnView()}>Zobraziť</button>
    </div>
  </div>
)

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return (
    <>
      { children !== null
    && (
    <div {...containerProps} className="search_results">
      {children}
    </div>
    )}
    </>
  )
}

class Autocomplete extends React.Component {
  constructor() {
    super()

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      isLoading: false,
      // eslint-disable-next-line react/no-unused-state
      suggestion: null
    }

    this.lastRequestId = null
  }

  onChange = (event, { newValue, method }) => {
    const newState = {
      value: newValue
    }
    // console.log('[onChange] 1', newValue, method)
    const currentSuggestion = this.getCurrentSuggestion(newValue)
    

    // if(this.state.isLoading ) {
    //   console.log('[onChange] LOADING')
    // } else {
    //   console.log('[onChange] NOT LOADING')
    // }

    // console.log('[onChange] 2', currentSuggestion)

    if(currentSuggestion !== null) {
      // console.log('[onChange] suggestion', currentSuggestion)
      // this.onSuggestionSelected(null, { suggestion: currentSuggestion})
      // this.handleOnView({currentSuggestion})
      // console.log('[onChange] new suggestion', newValue, method)
      newState.suggestion = currentSuggestion
    }
    this.setState(newState)
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    this.fetchInProgress = true
    // console.log('[onSuggestionsFetchRequested]',value, reason)
    this.loadSuggestions(value, reason)
  };

  onSuggestionsClearRequested = () => {
    // console.log('[onSuggestionsClearRequested] clear')
    this.setState({
      suggestions: []
    })
  };

  onSuggestionSelected = (event, { suggestion }) => {
    //  console.log('[onSuggestionSelected]', suggestion)
    this.setState({
      suggestion
    })
    this.handleOnView({suggestion})
  }

  handleOnView = (props) => {
    const selectedSuggestion = (props && props.suggestion) || this.state.suggestion
    this.props.setPage('page-ca-selected')
    // console.log('[handleOnView]', selectedSuggestion)
    // setTimeout(() => {
    //   scroller.scrollTo('page-ca-result',{
    //     smooth: true,
    //     duration: 1000
    //   })
    // }, 200)
    this.props.setCadastralArea(selectedSuggestion)
  }

  loadSuggestions(value, reason) {
    if (this.state.value === value) {
      return
    }

    // Cancel the previous request
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId)
    }

    this.setState({
      isLoading: true
    })

    this.lastRequestId = setTimeout(() => {
      // console.log(value)
      const options = {
        uri: `${BACKEND_URL}/api/search`,
        qs: {
          value
        },
        json: true
      }

      rp(options).then((response) => {
        const currentSuggestion = this.getCurrentSuggestion(value, response)
        // console.log('[loadSuggestions]', currentSuggestion);
        
        if(currentSuggestion !== null) {
          this.setState({
            isLoading: false,
            suggestions: response,
            suggestion: currentSuggestion
          })
        } else {
          this.setState({
            isLoading: false,
            suggestions: response
          })          
        }
        
        
      })
    }, 1000)
  }

  getCurrentSuggestion(title, suggestions) {
    const items = suggestions === undefined ? this.state.suggestions : suggestions
    let suggestion = null
    items.forEach(s => {
      if(Diacritics.clean(title) === s.titleSearch) {
        suggestion = s
      }
    })
    return suggestion
  }

  render() {
    const { value, suggestions, isLoading, suggestion } = this.state

    const inputProps = {
      placeholder: 'Zadajte katastrálne územie',
      value,
      onBlur: () => {
        // console.log('BLUR')
      },
      onChange: this.onChange,
      onKeyDown: (event) => {
        if (suggestion && event.keyCode === 13) {
          this.onSuggestionSelected(null, { suggestion })
          this.handleOnView({suggestion})
        }
      }      
    }
    const status = (isLoading ? 'Načítání' : '')
    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          // shouldRenderSuggestions={(value, reason)=> { 
          //   return true; //console.log('[shouldRenderSuggestions]',value, reason) 
          // }}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={{ input: 'form-control' }}
          renderInputComponent={(inputProps) => renderInputComponent({ ...inputProps }, this.handleOnView)}
          renderSuggestionsContainer={renderSuggestionsContainer}
        />
      </>

    )
  }
}

export default Autocomplete
